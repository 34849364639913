_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Please provide the matching value for {0}", //no i18n
	"crm.label.field":"Field",//no i18n
"crm.label.value":"Value",//no i18n
	"sentiment.criteria.wrongcriteria":"Criteria value cannot be more than {0}",//no i18n
"crm.mb.field.common.splc":"Special characters are not allowed. Enter a valid value.",//no i18n
	"crm.label.field.plural":"fields",//no i18n
	"crm.label.in.minutes":"{0} (in minutes)",//no i18n
	"crm.security.roles.list":"Roles List",//no i18n
"crm.security.roles.lookup.info":"Please select one of the roles from the following list.",//no i18n
"crm.territory.addterritory":"Add Territory",//no i18n
"crm.title.edit.territory":"Edit Territory",//no i18n
"crm.territory.title.assign.territories":"Assign Territories",//no i18n
	"crm.label.context.help":"Help",//no i18n
	"crm.label.from":"From",//no i18n
"crm.label.to":"To",//no i18n
	"crm.custom.field.less.than.equalto":"{0} should be less than or equal to {1}",//no i18n
	"workflow.option.webhookFailure.fromDate":"From Date",//no i18n
"workflow.option.webhookFailure.toDate":"To Date",//no i18n
	"crm.template.listview.search.no.results":"No results found",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"From Date cannot be greater than To Date.",//no i18n
	"crm.label.tag.new":"New Tag",//No I18n
	"crm.label.enter.tag":"Enter Tags",//No I18n
	"crux.comboBox.max.limit":"You cannot select more than {0} {1}.",//NO I18n
	"Administrator":"Administrator",//No I18n
	"Standard":"Standard",//No I18n
	"crm.button.add":"Add",//NO I18n
	"crm.label.users":"Users", //NO I18n
  "crm.workflow.alert.roles":"Roles", //NO I18n
  "crm.security.groups":"Groups", //NO I18n
	"crm.label.available" : "Available", //NO I18n
	"crm.label.assign.manually" : "Assign", //NO I18n
	"crm.globalsearch.option.all": "All", //NO I18n
	"webform.status.Active":"Active", //NO I18n
	"Inactive":"Inactive", //NO I18n
  "Confirmed":"Confirmed", //NO I18n
  "crm.user.component.unconfirmed":"Unconfirmed",//no i18n
  "DeletedUser":"Deleted", //NO I18n
  "crm.feed.group.admin":"Admin", //NO I18n
  "crm.ln.lable.current":"Current", //NO I18n
	"crm.label.selected": "Selected",//NO I18n
	"crm.auditlog.user": "User", //NO I18n
	"cob.role": "Role", //NO I18n
	"zoho.email": "Email", //NO I18n
	"Profile": "Profile", //NO I18n
	"crm.label.search.for.users": "Search Users", //NO I18n
	"crm.security.group.users.empty": "No Users Found", //NO I18n
	"crm.label.picklist.none": "--None--", //NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Call",//NO I18n
	"crm.button.cancel" : "Cancel",//NO I18n
	"crm.button.save" : "Save",//NO I18n
	"crm.zti.label.user": "User Name", //NO I18n\
	"crm.usrpop.non.selected" : "Selected Users",//No I18n
	"crm.globalsearch.search.title" : "Search",//No I18n
	"None" : "None",//No I18n
	"crm.label.criteria.pattern" : "Criteria Pattern",//No I18n
	"Search Users": "Search Users", //NO I18n
	"and" : "and", //No I18n
	"or" : "or", //No I18n
	"crm.label.or" : "OR", //No I18n
	"crm.label.and" : "AND", //No I18n
	"crm.field.valid.check" : "Please enter a valid {0}", //No I18n
	"is" : "is",//No I18n
	"isn\'t" : "isn't",//No I18n
	"contains" : "contains",//No I18n
	"doesn\'t contain" : "doesn't contain",//No I18n
	"starts with" : "starts with",//No I18n
	"ends with" : "ends with",//No I18n
	"is empty" : "is empty",//No I18n
	"is not empty" : "is not empty",//No I18n
	"is before" : "is before",//No I18n
	"is after" : "is after",//No I18n
	"between" : "between",//No I18n
	"not between" : "not between",//No I18n
	"Today" : "Today",//No I18n
	"Tomorrow":"Tomorrow",//No I18n
	"Tommorow Onwards" : "Tomorrow Onwards",//No I18n
	"Yesterday" : "Yesterday",//No I18n
	"Till Yesterday" : "Till Yesterday",//No I18n
	"Last Month" : "Last Month",//No I18n
	"Current Month" : "Current Month", //No I18n
	"Next Month" : "Next Month", //No I18n
	"Last Week" : "Last Week", //No I18n
	"Current Week" : "Current Week", //No I18n
	"Next Week" : "Next Week", //No I18n
	"Age in Days" : "Age in Days",//No I18n
	"Attended Dialled" : "Attended Dialed", //No I18n
	"Unattended Dialled" : "Unattended Dialed", //No I18n
	"Overdue" : "Overdue", //No I18n
	"Cancelled" : "Cancelled", //No I18n
	"Received" : "Received", //No I18n
	"Missed" : "Missed", //No I18n
	"crm.alert.character.not.allowed" : "{0} is not allowed", //No I18n
	"crm.condition.in.last" : "in the last", //No I18n
	"crm.zinvoice.dueIn" : "due in", //No I18n
	"crm.label.notSelected" : "Not Selected",//NO I18n
	"crm.phoneNo.Link.Title" : "Call using Skype",//NO I18n
	"crm.no.data.found" : "No data found.",//NO I18n
	"crm.label.no.options.found" : "No options found.",//No I18n
	"crm.globalsearch.search.title" : "Search",//No I18n
	"crm.label.edit.criteria.pattern" : "Edit Pattern",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Pattern brackets do not match.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Invalid brackets surrounding condition operator(s).",//No I18n
	"crm.criteria.number.notmatch.check" : "Please check the pattern at {0}.",//No I18n
	"criteria.error.alert.other.params" : "Invalid content in this pattern.", //No I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "The Criteria Pattern does not match the conditions you've chosen.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "The Criteria Pattern does not match the conditions you've chosen.", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Please enter a valid field label in the {0} row.", //No I18n
	"crm.criteria.condition.valid.check" : "Please specify a valid condition for {0}.", //No I18n
	"crm.custom.field.less.than.to" : "<i>From</i> range cannot be greater than <i>To</i> range.", //No I18n
	"crm.alert.label.savepattern" : "Save the pattern before changing criteria.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "You cannot add additional criteria.",//No I18n
	"Due in Days" : "Due in Days", //No I18n
	"Scheduled" : "Scheduled", //No I18n
	"on" : "On",//No I18n
	"before" : "before",//No I18n
	"crm.label.general.small.after" : "after",//No I18n
	"crm.thisweek" : "This Week",//No I18n
	"crm.label.this.month" : "This Month",//No I18n
	"crm.thisyear" : "This Year",//No I18n
	"crm.source.user.and.system" : "User & System",//No I18n
	"crm.source.user.or.system" : "User Or System",//No I18n
	"crm.label.system2" : "System",//No I18n
	"crm.source.user.only" : "Only by User",//No I18n
	"crm.source.system.only" : "Only by System",//No I18n
	"crm.condition.till.today" : "Until Today",//No I18n
	"game.month.left" : "1 month",//No I18n
	"game.months.left" : "{0} months",//No I18n
	"crm.condition.last.30.days" : "in the last 30 days",//No I18n
	"crm.condition.last.60.days" : "in the last 60 days",//No I18n
	"crm.condition.last.90.days" : "in the last 90 days",//No I18n
	"crm.label.filter.typehere" : "Type here", //No I18N
	"crm.filter.is.not" : "is not", //No I18n
	"crm.condition.until.now" : "Until Now",//No I18n
	"crm.filter.email.isblocked" : "is blocked",//No I18n
	"crm.filter.email.isnotblocked" : "is not blocked",//No I18n
	"crm.label.no.results.match" : "No results match",//No I18n
	"crm.label.select.user" : "Click to Select Users.", //No I18n
	"current.logged.in.user": "Logged in User", //NO I18n
	"current.logged.in.user.definition": "The user who initiates the Record Action.", //NO i18n
	"crm.security.group": "Group", //NO I18n
	"crm.security.role": "Role", //NO I18n
	"Date" : "Date",//No I18n
	"crm.field.valid.decimal.check2" : "Decimal places for the <i>{0}</i> field should be less than or equal to {1}.",//No I18n
	"crm.field.empty.check" : "{0} cannot be empty",//No I18n
	"crm.label.add.note": "Add Note", //NO I18n
	"crm.label.simply.by": "by", //NO I18n
	"crm.general.addnote": "Add a note", //NO I18n
	"crm.general.addtitle": "Add a title", //NO I18n
	"crm.label.attach.file": "Attach File", //NO I18N
	"crm.button.mass.delete": "Delete", //NO I18N
	"crm.warning.delete.record": 'Are you sure you want to delete "{0}"?', //NO I18N
	"crm.label.yes": "Yes", //NO I18N
	"crm.note.view.previous": "View Previous Notes", //NO I18N
  "of": "of", //NO I18N
	"crm.label.notes": "Notes", //NO I18N
	"crm.note.recent.first": "Recent First", //NO I18N
	"crm.note.recent.last": "Recent Last", //NO I18N
	"crm.territory.label.only": "{0} only", //no i18n
	"crm.select" : "Select",//No I18n
	"crm.button.apply.filter" : "Apply Filter",//No I18n
	"crm.alert.maximum.text.values.contains" : "You cannot enter more than {0} values for this field.",//No I18n
	"PM" : "PM",//No I18n

	"crm.mb.newversion.msg4":"OK, got it!", //no i18n
	"Jan":"Jan", //no i18n
	"Feb":"Feb", //no i18n
	"Mar":"Mar", //no i18n
	"Apr":"Apr", //no i18n
	"Jun":"Jun", //no i18n
	"Jul":"Jul", //no i18n
	"Aug":"Aug", //no i18n
	"Sep":"Sep", //no i18n
	"Oct":"Oct", //no i18n
	"Nov":"Nov", //no i18n
	"Dec":"Dec", //no i18n
	"crm.label.More" :"More", //no i18n
	"crm.label.unmapped.stages":"UnAccounted", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Show More",//No I18n
	"crm.wf.summary.label.HideInstantActions" : "Show Less",//No I18n
	//filter related keys-start
	"Sent":"Sent",//no i18n
	"Received":"Received",//no i18n
	"Notes":"Notes",//no i18n
	"Quote\ Stage":"{0} Stage",//no i18n
	"crm.module.owner":"{0} Owner",//no i18n
	"Potential\ Closing\ Date":"{0} Closing Date",//no i18n
	"crm.label.vendor.name":"{0} Name",//no i18n
	"days":"days",//no i18n
	"weeks":"weeks",//no i18n
	"months":"months",//no i18n
	"Last\ Week":"Last Week",//no i18n
	"Last\ Month":"Last Month",//no i18n
	"crm.module.name":" {0} Name",//no i18n
	"Campaign":"Campaign",//no i18n
	"Tasks":"Tasks",//no i18n
	"Calls":"Calls",//no i18n
	"Events":"Events",//no i18n
	"Activities":"Activities",//no i18n
	"Today\ +\ Overdue":"Today + Overdue",//no i18n
	"User":"User",//no i18n
	"Cancelled":"Cancelled",//no i18n
	"crm.events.duration":"Duration",//no i18n
	"crm.title.clear.name":"Clear",//no i18n
	"crm.lead.prediction.convert.high":"High",//no i18n
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.convert.medium":"Medium",//no i18n
	"crm.lead.prediction.convert.low":"Low",//no i18n
	"crm.lead.prediction.tooltip.convert":"LIKELINESS",//no i18n
	"crm.lead.prediction.tooltip.score":"SCORE",//no i18n
	"Planned":"Planned",//no i18n
	"Invited":"Invited",//no i18n
	"Opened":"Opened",//no i18n
	"Responded":"Responded",//no i18n
	"Bounced":"Bounced",//no i18n
	"Opted\ Out":"Opted Out",//no i18n
	"crm.filter.label.with.open":"With Open {0}",//no i18n
	"crm.filter.label.without.open":"Without Open {0}",//no i18n
	"crm.filter.label.without.any":"Without Any {0}",//no i18n
	"crm.filter.label.with.module":"With {0}",//no i18n
	"crm.filter.label.activity.due":"{0} Due",//no i18n
	"crm.filter.label.activity.done":"{0} Done",//no i18n
	"crm.filter.label.notes.added":"Notes Added",//no i18n
	"crm.label.filter.email.status":"Latest Email Status",//no i18n
	"crm.label.filter.email.clicked":"clicked",//no i18n
	"crm.label.filter.email.responded":"responded", //no i18n
	"crm.label.filter.email.info":"Filter records based on the latest status of your sent/received emails.",//no i18n
	"crm.filter.label.sent":"sent",//no i18n
	"crm.filter.label.not.sent":"not sent",//no i18n
	"crm.filter.label.opened":"opened",//no i18n
	"crm.filter.label.not.opened":"not opened",//no i18n
	"crm.filter.label.received":"received",//no i18n
	"crm.filter.label.not.received":"not received",//no i18n
	"crm.filter.label.bounced":"bounced",//no i18n
	"crm.filter.label.opened.not.replied":"opened and not responded",//no i18n
	"crm.filter.label.any":"Any of the above",//no i18n
	"crm.zia.config.potential.amount":"{0} Amount",//no i18n
	"crm.lead.prediction.likely.convert":"Likely to convert",//no i18n
	"crm.predictions.feature.label":"Prediction",//no i18n
	"crm.intelligence.prediction.likelywin":"Likely to Win",//no i18n
	"crm.intelligence.prediction.likelylose":"Likely to Lose",//no i18n
	"crm.intelligence.prediction.halfchance":"50:50 Chance",//no i18n
	"crm.intelligence.prediction.score":"Prediction Score",//no i18n
	"crm.lead.prediction.recent.score":"Recent prediction score",//no i18n
	"crm.intelligence.prediction.lastconv":"Last 3 conversations",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Records to focus",//no i18n
	"crm.intelligence.prediction.slowmoving":"Slow Moving",//no i18n
	"crm.intelligence.prediction.trend.down":"Recently Trending down",//no i18n
	"crm.label.touched.records":"Touched Records",//no i18n
	"crm.label.untouched.records":"Untouched Records",//no i18n
	"crm.label.record.action":"Record Action",//no i18n
	"workflow.rule.view.label.Modified":"Modified",//no i18n
	"crm.label.not.modified":"Not Modified",//no i18n
	"crm.label.related.records.action":"Related Records Action",//no i18n
	"Done":"Done",//no i18n
	"crm.label.not.done":"Not Done",//no i18n
	"sentiment.model":"Email Sentiment",//no i18n
	"sentiment.criteria.count":"Count",//no i18n
	"sentiment.criteria.percentage":"Percentage",//no i18n
	"sentiment.criteria.lastmail":"For the last email",//no i18n
	"Chats":"Chats",//no i18n
	"Attended":"Attended",//no i18n
	"crm.lead.prediction.popup.text":"When Likely to convert is {0}, the prediction score should be between {1}.",//no i18n
	"crm.lead.prediction.popup.final":"Please change the filter accordingly and try again.",//no i18n
	"crm.custom.field.less.than.to1":"From range cannot be greater than To range.",//no i18n
	"Last\ Activity\ Date":"Last Activity Date",//no i18n
	"crm.label.general.small.after":"after",//no i18n
	"sentiment.criteria.wrongcriteria":"Criteria value cannot be more than {0}",//no i18n
	"crm.chosen.minimum.input.text":"Please enter {0} or more characters",//no i18n
	"crm.intelligence.prediction.trendup":"Trending up",//no i18n
	"crm.intelligence.prediction.trenddown":"Trending down",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Completed" ,//no i18n
	"crm.label.success":"Success" ,//no i18n
	"crm.label.Failure":"Failure" ,//no i18n
	"Both":"Both" ,//no i18n
	"crm.condition.cannot.empty":"Condition cannot be empty.",//no i18n
	"crm.condition.last.30.days":"in the last 30 days",//no i18n
	"crm.condition.last.60.days":"in the last 60 days",//no i18n
	"crm.condition.last.90.days":"in the last 90 days",//no i18n
	"crm.sentiment.Positive":"Positive",//no i18n
	"crm.sentiment.Negative":"Negative",//no i18n
	"sentiment.positiveandnegative":"Positive and Negative",//no i18n
	"sentiment.positiveornegative":"Positive or Negative",//no i18n
	"sentiment.positiveonly":"Positive Only",//no i18n
	"sentiment.negativeonly":"Negative Only",//no i18n
	"crm.sentiment.Neutral":"Neutral",//no i18n
	"crm.filters.select.campaign.type":"Select {0} Type",//no i18n
	"crm.filters.select.campaign.status":"Select {0} Status",//no i18n
	"campaign.Member" : "Member",//no i18n
	"Service":"Service",//no i18n
	"crm.livedesk.pot.nextdays":"Next {0} days",//no i18n
	"crm.source.user.and.system":"User & System",//no i18n
	"crm.source.user.or.system":"User Or System",//no i18n
	"crm.source.user.only":"Only by User",//no i18n
	"crm.source.system.only":"Only by System",//no i18n
	"Scheduled":"Scheduled",//no i18n
	"Attended\ Dialled":"Attended Dialed",//no i18n
	"Unattended\ Dialled":"Unattended Dialed",//no i18n
	"crm.filter.email.isblocked":"is blocked",//no i18n
	"crm.filter.email.isnotblocked":"is not blocked",//no i18n
	"condition.till.now":"Until Now",//no i18n
	"crm.recurring.no.months":"{0} months",//no i18n
	"crm.lead.prediction.tooltip":"Likely to convert - Score Range",//no i18n
	"crm.website.activity":"Website Activity",//no i18n
	"crm.label.By":"By",//no i18n
	"crm.chosen.searching.text":"Searching...",//no i18n
	"crm.label.memberstatus.is":"and Member Status is",//no i18n
	"crm.label.status.is":"and status is",//no i18n
	"zia.last3.help":"Conversation include calls, tasks, {0}, email received, notes, visits, social comments, support Requests from Desk.",//no i18n
	"crm.label.tag.related.to":"related to",//no i18n

	//filter related keys-end
	"crm.label.account.created" : "New {0} will be created.",//No I18n
	"crm.krp.no.records.found" : "No {0} found",//No I18n
	"crm.module.new" : "New {0}",//No I18n
	"crm.label.view" : "View",//No I18n
	"crm.nsocial.customers" : "Customers",//No I18n
	"crm.nsocial.open.potential" : "Open {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Others",//No i18n
	"crm.field.length.check" : "{0} value exceeds the maximum length.", //No I18n
	"crm.lower.now": "now", //No I18n
	"crm.time.min.ago": "{0} mins. ago", //No I18n
	"crm.time.mins.ago":" {0} mins. ago", //No I18n
	"crm.time.hr.ago": "{0} hr. ago", //No I18n
	"crm.time.hrs.ago": "{0} hrs. ago", //No I18n
	"AllUsers": "All Users", //no i18n
	"crm.label.search":"Search",//no i18n
	"crm.api.filterby":"Filter by",//no i18n
	"crm.customview.nofields.found":"--No Matching Fields--",//no i18n
	"crm.setup.system.ziarecommendation":"Recommendation",//no i18n
	"crm.filter.label.all.products":"All {0}",//no i18n
	"crm.filter.label.select.products":"Selected {0}",//no i18n
	"crm.reviewprocess.smart.filter":"Review Process Record status",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Please choose a value.",//no i18n
	"crm.segmentation.segment.score":"Segment Score",//no i18n
	"crm.filter.label.in":"based on",//no i18n
	"crm.filter.label.and.purchase.in":"and Likely to Purchase in",//no i18n
	"crm.filter.label.last.purchased":"and recently acquired",//no i18n
	"crm.filter.label.a.day":"a Day",//no i18n
	"crm.filter.label.a.week":"a Week",//no i18n
	"crm.filter.label.a.month":"a Month",//no i18n
	"crm.cal.custom":"Custom",//no i18n
	"crm.mb.field.common.empt":"The value cannot be empty.",//no i18n
	"crm.chosen.error.loading.text":"Oops, we weren't able to load your results",//no i18n
	"crm.filter.label.firstbuy":"First time",//no i18n
	"crm.filter.label.cwbab":"Dependent",//no i18n
	"crm.filter.label.fbt":"Bundle",//no i18n
	"crm.filter.label.rebuy":"Repeat",//no i18n
	"crm.filter.label.nextbuy":"Sequence",//no i18n
	"crm.mxnlookup.select" : "Assign {0}",//No I18n
	"crm.lookup.chooserecord":"Choose {0}",//no i18n
	"crm.record.selected":"Selected {0}",//no i18n
	"crm.module.empty.message" : "No {0} found",//No I18n
	"crm.mxnlookup.selected" : "Assigned {0}",//No I18n
	"crm.security.error" : "Insufficient Privileges to perform this operation. Contact your Administrator.", //no i18n
	"crm.label.timeZone": "Time Zone", //NO I18n
	"crm.label.insufficient.privileges": "Insufficient Privileges to perform this operation.Contact your Administrator.", //NO I18n
    "crm.filter.header.secton.system": "System Defined Filters",//NO I18N
    "crm.filter.header.secton.fields": "Filter By Fields",//NO I18N
	"crm.createfield.calcinfo.new" : "This field acts as a calculator for any expression you input.</br> <b>E.g. 20+20</b> will yield <b>40</b> automatically",//No i18n
	"crm.lable.read.only" : "Read only field",//No i18n
	"crm.column.sort.asc" : "Asc",//No I18n
	"crm.column.sort.desc" : "Desc",//No i18n
	"crm.column.unsort" : "Unsort",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Share to customer", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "Only {0} characters are allowed for {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Shared with Customer", //NO I18N
	"crm.label.creator.noPermission" : "Permission Denied", //no i18n
	"crm.button.ok" : "OK", //no i18n
	"crm.role.already.selected" : "This role is already selected", //no i18n
	"crm.user.deleted": "USER HAS BEEN DELETED",  //NO I18N
	"crm.account.closed": "THIS ACCOUNT HAS BEEN CLOSED",  //NO I18N
	"crm.start.chat": "Start a chat",  //NO I18N
	"crm.start.call": "Start a call",  //NO I18N
	"crm.recipient.invalid.email" : "Invalid emails found.", //NO I18N
	"crm.recipient.add.recipient" : "Add additional recipient", //NO I18N
	"crm.start.video.call": "Start a video call",  //NO I18N 	//ignorei18n_start

	"crm.label.scoring.rules":"Scoring Rules",
	"Score":"Score",
	"Positive Score":"Positive Score",
	"Negative Score":"Negative Score",
	"Touch Point Score":"Touch Point Score",
	"Positive Touch Point Score":"Positive Touch Point Score",
	"Negative Touch Point Score":"Negative Touch Point Score",
	"crm.label.type.minutes": "Type here in minutes", //NO I18N
	"is OPEN" : "is OPEN", //no i18n
	"is WON" : "is WON", //no i18n
	"is LOST" : "is LOST", //no i18n
	"crm.potential.all.open":"All Open stages",//no i18n
	"crm.potential.all.won":"All Closed Won stages",//no i18n
	"crm.potential.all.lost":"All Closed Lost stages", //no i18n

	"crm.campaign.member.status" : "Member Status",//no i18n
	"crm.dashboard.select.type" : "Select {0}",//no i18n
	"crm.campaign.service.status":"Service Status",//no i18n

	"crm.fileuploader.message.responseerror": "Upload failed", //NO i18n
	"crm.storage.create.error": "No new records can be created as you have reached your maximum data storage limit.", //NO I18N
	"crm.storage.create.error.client": "No new records can be created because your admin has reached their maximum storage limit. Contact {0} to resolve this issue.", //NO I18N
	"crm.storage.avail.info": "({0} remaining of {1})", //NO I18N
	"crm.storage.error.key.manage": "Manage your data storage", //NO I18N
	"Records": "Records", //NO I18N
	"crm.workflow.alert.additional.recipients" : "Additional Recipients", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Use comma to separate more than one email address.", //NO I18N
	"crm.related.contact.account" : "{0} related to {1}",//No I18n
	"crm.allcontact.show" : "All {0}",//No I18n
	"crm.button.mass.show" : "Show",//No I18n
	"crm.msg.custom.view.not.replied" : "Not Replied Messages", //NO I18N
	"crm.msg.custom.view.replied" : "Replied Messages",//NO I18N
	"crm.workflow.select.recipients" : "Recipient", //NO I18N
	"crm.user.label.active.confirmed.admins": "Active Confirmed Admins", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Please select at least one profile.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Sorry, but you can't remove a default profile.",//NO I18N
	"crm.inv.label.add.emails" : "Add Emails", //NO I18N
	"crm.prediction.analytics.filter.year" : "Last Year",
	"Previous\ FY":"Previous FY",//no i18n
	"Current\ FY":"Current FY",//no i18n
	"Next\ FY":"Next FY",//no i18n
	"Current\ FQ":"Current FQ",//no i18n
	"Next\ FQ":"Next FQ",//no i18n
	"Previous\ FQ":"Previous FQ",//no i18n
	"crm.button.back.alone":"Back",//no i18n
	"crm.picklist.sample.text":"Sample Text",//no i18n
	"crm.more.colors":"More Colors",//no i18n
	"crm.field.label.email":"Email",//no i18n
"crm.zia.nba.feature.label":"Next best experience",//no i18n
"Meeting":"Meeting",//no i18n
"crm.gdpr.notavailable.field":"Not Available",//NO I18N
'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} should be greater than or equal to {1}",
	"crux.users.selected.plural" : "{0} users selected.",
	"crux.user.selected.singular" :"{0} user selected.",
	"crux.criteria.empty.secondaryfield.module" : "No matching {0} field found in {1} module",
	"crux.criteria.empty.secondaryfield" : "No other {0} field available for comparison, please enter a value to compare.",
	"crux.logged.in.role.definition" : "The role of the user who initiates the record actions",
	"zia.similarity.smartfilter.score":"Similarity score",//no i18n
	"zia.similarity.smartfilter.records":"Similar {0} of",//no i18n
	"zia.similarity.smartfilter.records.search":"Show similar {0} of",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"You can't select more than {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} should be greater than or equal to {1}",
	"crux.users.selected.plural" : "{0} users selected.",
	"crux.user.selected.singular" :"{0} user selected.",
	"crux.criteria.empty.secondaryfield.module" : "No matching {0} field found in {1} module",
	"crux.criteria.empty.secondaryfield" : "No other {0} field available for comparison, please enter a value to compare.",
	"crux.logged.in.role.definition" : "The role of the user who initiates the record actions",
	"crux.max.limit.unselect" : "You cannot unselect more than {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d is already selected" //NO I18N
}
